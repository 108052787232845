// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoices-js": () => import("./../../src/pages/invoices.js" /* webpackChunkName: "component---src-pages-invoices-js" */),
  "component---src-pages-legal-project-management-js": () => import("./../../src/pages/legal-project-management.js" /* webpackChunkName: "component---src-pages-legal-project-management-js" */),
  "component---src-pages-login-js": () => import("./../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-networks-js": () => import("./../../src/pages/networks.js" /* webpackChunkName: "component---src-pages-networks-js" */),
  "component---src-pages-our-team-js": () => import("./../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-payments-js": () => import("./../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-policy-js": () => import("./../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-team-management-js": () => import("./../../src/pages/team-management.js" /* webpackChunkName: "component---src-pages-team-management-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

